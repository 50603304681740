import React from "react";
// import { Link } from "gatsby";
import { OutboundLink } from "gatsby-plugin-google-analytics";
import { FaGlobe } from "@react-icons/all-files/fa/FaGlobe";

import Head from "../../components/head.js";
import Layout from "../../components/layout";

// var choice = Math.floor(Math.random() * 2);
// var choice = 0;

const Benji = () => {
  return (
    <Layout>
      <Head title="Archie Marshall / King Krule" />
      <h3>Archie Marshall / King Krule</h3>
      <h4>
        <FaGlobe />
        London, England
      </h4>
      {/* <p>
        <OutboundLink href="https://benjifriedman.com">Website</OutboundLink>
      </p> */}
      <p>
        <OutboundLink href="https://kingkrule.net/">Website</OutboundLink>
      </p>
      <p>
        <OutboundLink href="https://www.instagram.com/edgar_the_breathtaker">
          Instagram
        </OutboundLink>
      </p>
      <p>
        <OutboundLink href="https://en.wikipedia.org/wiki/King_Krule">
          Wikipedia
        </OutboundLink>
      </p>
      {/* <p>
        <OutboundLink href="https://ello.co/benjifriedman">Ello</OutboundLink>
      </p> */}
      {/* <p>
        <OutboundLink href="https://benjifriedman.xyz">
          Framed Prints and T-Shirts
        </OutboundLink>
      </p> */}
      <p>
        Archy Ivan Marshall, also known by his stage name King Krule, among
        other names, is an English singer, songwriter, musician, rapper and
        record producer.
      </p>
      <p>
        He began recording music in 2010 under the moniker Zoo Kid. The
        following year he adopted his present name. He has released several EPs
        and his debut full-length album, 6 Feet Beneath the Moon, was released
        in 2013 to positive critical reception. His third album, The Ooz, was
        released on 13 October 2017 and his fourth album, Man Alive!, was
        released on 21 February 2020 both to further critical acclaim.
      </p>
      <p>
        His music blends elements of punk jazz with hip hop, darkwave, trip hop
        and post-punk.
      </p>
      <div className="video-container">
        <iframe
          width="560"
          height="315"
          src="https://www.youtube.com/embed/K5-f1Bnltu8?si=KNVXpzpuhAwisfr9"
          title="YouTube video player"
          frameborder="0"
          allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
          allowfullscreen
        ></iframe>
      </div>

      <div className="video-container">
        <iframe
          width="560"
          height="315"
          src="https://www.youtube.com/embed/yL8e_uUGsz4?si=eZlFpSo5jnBNeAS_"
          title="YouTube video player"
          frameborder="0"
          allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
          allowfullscreen
        ></iframe>
      </div>
      <div className="video-container">
        <iframe
          width="560"
          height="315"
          src="https://www.youtube.com/embed/rQrDgQiWJV0?si=6DbhSwxSCVJ_2iGC"
          title="YouTube video player"
          frameborder="0"
          allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
          allowfullscreen
        ></iframe>
      </div>
      <div className="video-container">
        <iframe
          width="560"
          height="315"
          src="https://www.youtube.com/embed/HPLa6dSx-rA?si=SugSmbr8A14EhjVP"
          title="YouTube video player"
          frameborder="0"
          allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
          allowfullscreen
        ></iframe>
      </div>
    </Layout>
  );
};

export default Benji;
